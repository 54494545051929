@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

:root {
    --primary-color: #7BD626;
     --secondary-color: #5D7F33;
    --dark-color: #515151;
    --light-color: #f4f4f4;
    --text-color: #333c4d;
    --background-color: white;
    --footer-background-color: #f5f5fa;
    --link-highlight: #333;
    --border-bottom: #f5f5fa;
}

* { 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: var(--text-color);    
    background-color: var(--background-color);
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: var(--text-color);
}



h1,
h2 {
    font-weight: 300;
    line-height: 1.2;
    margin: 10px 0;
}

img {
    width: 100%;
}



section {
    margin-top: 1rem;
}

span {
    color:var(--dark-color);
    font-size: small;
}

footer {
    margin-top: 1rem;
    border-top: var(--border-bottom) 1px solid;
    background-color: var(--footer-background-color);
}

main {
    max-width: 1100px;
    margin: 0 auto;
}

summary{
    margin-bottom: 1rem;
}

article p {
    display: block;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 1rem;
}

article img {
    border-radius: 5px;
}

/* Navigation Bar */

.navbar {
    height: 6rem;
    border-bottom: #f5f5fa 1px solid;
    position: sticky;    
    background-color: var(--background-color);
    z-index: 100;
}
.navbar ul {    
    display: flex;
    justify-content: space-between;    
    align-items: center;

}

.navbar .logo:hover {
    border-bottom: 0;
}

.navbar a {
    color: var(--text-color);
    padding: 5px;    
}

.navbar a:hover {
    border-bottom: 2px #000 solid;
}

.navbar .flex {
    justify-content: space-between;
}



.menu-btn {
    display: inline-block;
    padding: 5px 15px;
    cursor: pointer;
    background: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
}

.menu-btn-outline {
    background-color: transparent;
    border: 1px #000 solid;
    color: var(--text-color);
}


@media (min-width: 768px) {
    .menu-btn {
        display: none;
    }    
}

.mobile-nav{
    z-index: 10;
    transition: display 0.4s ease-in;
}

.mobile-nav a{
    color: var(--text-color);
    font-size: 2.5rem;
}

/* Header */

.header {
    margin-bottom: 2rem;
}

.header .container h1{
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
}

.header .container p {
    color: #777;
    font-size: .8rem;
}

.header .container img {
    width: 150px;
    height: 150px;
}

/* Top News */

.top-news .grid {
    overflow: visible;
    grid-template-columns: 65% 35%;
}

.top-news img {    
    border-start-start-radius: 5px;    
    border-end-start-radius: 5px;
}

.top-news a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.1rem; 
}

.top-news .flex {
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}


/* Tag Container */

.tag-container {
    display: flex;
    justify-content: space-between;
    height: 1rem;
    align-items: center;    
    font-size: xx-small;
}

.tag-container .news-source {
    display: flex;    
    align-items: center;    
}


.tag-container .news-source em {
    display: flex;   
    width: 1rem;
    height: 1rem;
    background: #ddd;
    align-items: center;
    justify-content: center;
    border-radius: 50%;    
    font-style: normal;
    margin-right: .1rem;
}




/* Cards */

.cards .container {
    display: flex;
    justify-content: space-between;    
    flex-wrap: wrap;

}

.card {
    flex: 1;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1rem;    
}

.card a{
    color: #333;
    text-decoration: none;
    font-size: 1.1rem; 
}

.card .card-header img {  
    border-radius: 0.5rem;    
}

.card .card-header h3 {  
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 700;
}

.card .card-text {
    font-size: .8rem;
    font-weight: 400;
    color: #777;
}

/* Competitions */

.competitions .competitions-list .competition {    
    background-color: transparent;
    border: #333 solid 1px;
    border-radius: 1rem;    
    transition: transform 0.2s ease-in;
}

.competition:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0,  0.2);
    transform: translateY(-15px);
}

.competition .image-wrapper {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.competition .image-wrapper img {
    width: 150px;
    height: 150px;
}   

.competition .image-wrapper p {
    font-size: larger;
    font-weight: bold;
    margin-left: 1rem;
}

/* Profile */
.profile-header {
    border-bottom: 1px var(--border-bottom) solid;
}

.profile-header h2{
    font-weight: bold;
    text-transform: capitalize;
    font-size: 3vw;
}

.profile-pic-wrapper img{
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
}

.profile-pic-wrapper span {
    width: 10rem;
    height: 10rem;    
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 4vw;
    color: var(--light-color);
    text-transform: uppercase;
}


/* Footer */
.footer .grid {
    grid-template-columns: 1fr;
}

.footer .container .footer-navs {
    display: flex;
    justify-content: space-between;
}

.footer a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 0.9rem;    
}


/* Tablest and under  */
@media(max-width: 768px) {  
    .grid,
    .top-news .grid,
    .competitions .grid
    {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }   

    article img,
    .top-news img {    
        border-radius: 5px;
    }
    
    .header .container h1 {
        font-size: 2rem;
    }

    .profile-header h2{
        font-size: 5vw;        
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        
    }

    .profile-pic-wrapper img {
        width: 7rem;
        height: 7rem;
    }

    .profile-pic-wrapper span {
        width: 7rem;
        height: 7rem;
    }
}

@media(max-width: 425px) {  
    .profile-header h2{        
        max-width: 16rem;        
    }
}

@media(max-width: 375px) {  
    .profile-header h2{        
        max-width: 13rem;        
    }
}

@media(max-width: 320px) {  
    .profile-header h2{        
        max-width: 10rem;        
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --primary-color: #7BD626;
        --secondary-color: #5D7F33;
        --dark-color: #515151;
        --light-color: #f4f4f4;
        --text-color: #f4f4f4;
        --background-color: black;
        --footer-background-color: #1a1a1a;
        --link-highlight: #e1ff57;
    }
    
    header span {
        color: var(--text-color);
    }

    a:hover {
      color: var(--link-highlight);
    }  

    span {
        color:var(--light-color);
    }

    .btn-outline {
        background-color: var(--light-color);
        border: 1px var(--light-color) solid;
        color: var(--dark-color);
    }

    .competitions .competitions-list .competition {
        background-color: #555454;
    }

    .competition:hover {
        box-shadow: 0 3px 10px var(--link-highlight);
    }
  }