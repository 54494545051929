.container {
    max-width: 1100px;
    margin: 0 auto;
    overflow: auto;
    padding: 0 10px;
}

.block {
    display: block;
}

.hidden {
    display: none;
}

.w-screen {
    width: 100vw;
}

.h-screen {
    height: 100vh;
}

.w-auto {
    width: auto;
}

.w-full {
    width: 100%;
}

.fixed {
    position: fixed;
}
.card {
    background-color: #fff;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
}

.btn {
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    background: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 3px;
}

.btn-outline {
    background-color: transparent;
    border: 1px #000 solid;
    color: var(--text-color);
}

.btn:hover {
    transform: scale(0.98);
}

/* Backgrounds & colred buttons */
.bg-primary, 
.btn-primary {
    background-color: var(--primary-color);
    color: var(--text-color);
}

.bg-secondary, 
.btn-secondary {
    background-color: var(--secondary-color);
    color: #fff;
}

.bg-dark, 
.btn-dark {
    background-color: var(--dark-color);
    color: #fff;
}

.bg-light, 
.btn-light {
    background-color: var(--light-color);
    color: #fff;
}

.bg-primary a, 
.btn-primary a,
.bg-secondary a, 
.btn-secondary a,
.bg-dark a, 
.btn-dark a,
.bg-light a, 
.btn-light a {
    color: #fff;
}

/* Text sizes */
.lead {
    font-size: 20px;
}

.sm {
    font-size: 1rem;    
}

.md {
    font-size: 2re;
}

.lg {
    font-size: 3rem;
}

.xl {
    font-size: 4rem;
}

.text-center {
    text-align: center;
}


.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-start {
    justify-content: flex-start;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem/* 24px */;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid-1 {
    grid-template-columns: 1fr;
}

.grid-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

/* Margin */

.mx-1 {
    margin: 0 1rem;
}

.my-1 {
    margin: 1rem 0;
}
.my-2 {
    margin: 1.5rem 0;
}
.my-3 {
    margin: 2rem 0;
}
.my-4 {
    margin: 3rem 0;
}
.my-5 {
    margin: 4rem 0;
}

.m-1 {
    margin: 1rem;
}
.m-2 {
    margin: 1.5rem;
}
.m-3 {
    margin: 2rem;
}
.m-4 {
    margin: 3rem;
}
.m-5 {
    margin: 4rem;
}

/* Padding */
.py-1 {
    padding: 1rem 0;
}
.py-2 {
    padding: 1.5rem 0;
}
.py-3 {
    padding: 2rem 0;
}
.py-4 {
    padding: 3rem 0;
}
.py-5 {
    padding: 4rem 0;
}

.py-6 {
    padding: 5rem 0;
}

.p-1 {
    padding: 1rem ;
}
.p-2 {
    padding: 1.5rem ;
}
.p-3 {
    padding: 2rem ;
}
.p-4 {
    padding: 3rem ;
}
.p-5 {
    padding: 4rem ;
}

.top-0 {
    top: 0;
}

.left-0{
    left: 0;
}

.overflow-y-scroll{
    overflow-y: scroll;
}

.z-10 {
    z-index: 10;
}

.pb-6 {
    padding-bottom: 1.5rem;
}

.h-full{
    height: 100%;
}

.h-8 {
    height: 2rem/* 32px */;
}


@media (min-width: 768px) {
    .md\:hidden {
        display: none;
    }

    .hidden {
        display: block;
    }
}

